import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Brand from "../brand"

const HeaderNavigation = ({ items, title, menu, isHome, toggleMenu }) => {
  const rootClass = `${menu ? "hidden" : "relative"} pt-6`
  const linkClass = `ml-8 font-bold transition duration-150 ease-in-out ${
    isHome ? "text-primary-500" : "text-gray-500 hover:text-gray-900"
  }`

  return (
    <div className={rootClass}>
      <nav className="relative flex flex-wrap items-center justify-between lg:justify-start">
        <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
          <div className="flex items-center justify-between w-full md:w-auto">
            <Brand title={title} />

            <div className="flex items-center -mr-2 md:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                id="main-menu"
                aria-label="Main menu"
                aria-haspopup="true"
                onClick={() => toggleMenu(true)}
              >
                <svg
                  className="w-6 h-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="hidden md:block md:ml-10 md:pr-4">
          {items.map(({ label, to }) => (
            <Link to={to} key={label} className={linkClass}>
              {label}
            </Link>
          ))}
        </div>
      </nav>
    </div>
  )
}

export const propTypes = {
  menu: PropTypes.bool,
  isHome: PropTypes.bool,
  toggleMenu: PropTypes.func,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, to: PropTypes.string })
  ),
}

HeaderNavigation.propTypes = propTypes

export default HeaderNavigation
