import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Section from "./section"

const CTA = ({ text, textAccent, cta }) => (
  <Section full background="bg-gradient-to-r from-primary-100 to-gray-100">
    <div className="lg:flex lg:items-center lg:justify-between">
      <h2 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        {text}
        <br />
        <span className="text-primary-600">{textAccent}</span>
      </h2>

      {(cta?.primary?.label || cta?.secondary?.label) && (
        <div className="flex mt-8 lg:flex-shrink-0 lg:mt-0">
          {cta?.primary?.label && (
            <div className="inline-flex rounded-md shadow">
              <Link
                to={cta.primary.to}
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-primary-600 hover:bg-primary-500 focus:outline-none focus:shadow-outline"
              >
                {cta.primary.label}
              </Link>
            </div>
          )}

          {cta?.secondary?.label && (
            <div className="inline-flex ml-3 rounded-md shadow">
              <Link
                to={cta.secondary.to}
                className="inline-flex items-center justify-center px-5 py-3 text-base font-medium leading-6 transition duration-150 ease-in-out bg-white border border-transparent rounded-md text-primary-600 hover:text-primary-500 focus:outline-none focus:shadow-outline"
              >
                {cta.secondary.label}
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  </Section>
)

CTA.propTypes = {
  text: PropTypes.string,
  textAccent: PropTypes.string,
  cta: PropTypes.shape({
    primary: PropTypes.shape({ label: PropTypes.string, to: PropTypes.string }),
    secondary: PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    }),
  }),
}

export default CTA
