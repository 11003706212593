import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Brand = ({ title, small }) => (
  <Link
    to="/"
    className="flex items-center justify-center font-medium text-primary-600 title-font md:justify-start"
  >
    <svg
      className={`${
        small ? "w-10 h-10" : "w-12 h-12"
      } p-2 rounded-full text-inherit`}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
      ></path>
    </svg>

    {title && (
      <span className={`text-inherit ${small ? "text-lg" : "text-xl"}`}>
        {title}
      </span>
    )}
  </Link>
)

Brand.propTypes = {
  title: PropTypes.string,
  small: PropTypes.bool,
}

Brand.defaultProps = {
  small: false,
}

export default Brand
