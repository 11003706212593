/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import CTA from "./cta"
import Header from "./header"
import Footer from "./footer"

const cta = { label: "Maak een afspraak", to: "/appointment" }

const Layout = ({ hero, children, uri, path }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const title = data.site.siteMetadata?.title
  const isAppointment = uri === "/appointment"
  const isHome = uri === "/"

  return (
    <>
      <Header title={title} hero={hero} isHome={isHome} />
      <main>{children}</main>
      {!isAppointment && (
        <CTA
          text="Wil je een afspraak maken?"
          textAccent="Aarzel niet!"
          cta={{ primary: cta }}
        />
      )}

      <Footer title={title} />
    </>
  )
}

export const HeroShape = {
  text: PropTypes.string,
  headline: PropTypes.string.isRequired,
  headlineAccent: PropTypes.string.isRequired,
  image: PropTypes.shape({}),
  cta: PropTypes.shape({
    primary: PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    }),
    secondary: PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    }),
  }),
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  uri: PropTypes.string,
  hero: PropTypes.shape(HeroShape),
}

export default Layout
