import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-plugin-transition-link/AniLink"
import Brand from "../brand"
import { propTypes as navigationPropTypes } from "./navigation"

const HeaderNavigationMobile = ({
  items,
  title,
  menu,
  toggleMenu,
  cta,
  isHome,
}) => {
  if (!menu) return null

  return (
    // <!--
    //      Mobile menu, show/hide based on menu open state.

    //      Entering: "duration-150 ease-out"
    //        From: "opacity-0 scale-95"
    //        To: "opacity-100 scale-100"
    //      Leaving: "duration-100 ease-in"
    //        From: "opacity-100 scale-100"
    //        To: "opacity-0 scale-95"
    //    -->
    <div className="relative inset-x-0 top-0 pt-4 pb-12 transition origin-top-right transform md:hidden">
      <div className="rounded-lg shadow-md">
        <div
          className="overflow-hidden bg-white bg-opacity-50 rounded-lg shadow-xl"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="main-menu"
        >
          <div className="flex items-center justify-between px-5 pt-4">
            <Brand title={title} />

            <div className="-mr-2">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                aria-label="Close menu"
                onClick={() => toggleMenu(false)}
              >
                <svg
                  className="w-6 h-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="px-2 pt-2 pb-3">
            {items.map(({ label, to }) => (
              <Link
                to={to}
                swipe
                direction="left"
                className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                role="menuitem"
              >
                {label}
              </Link>
            ))}
          </div>

          {false && cta?.label && cta?.to && (
            <Link
              to={cta.to}
              swipe
              direction="left"
              className="block w-full px-5 py-3 font-medium text-center text-white transition duration-150 ease-in-out bg-primary-600 hover:bg-primary-500 focus:outline-none focus:bg-primary-100"
              role="menuitem"
            >
              {cta.label}
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

HeaderNavigationMobile.propTypes = {
  ...navigationPropTypes,
  cta: PropTypes.shape({ label: PropTypes.string, to: PropTypes.string }),
  isHome: PropTypes.bool,
}

export default HeaderNavigationMobile
