import React from "react"
import PropTypes from "prop-types"

const Section = ({ title, subtitle, text, background, children, full }) => {
  let rootClass = `${background}`
  let innerClass = ``

  if (full) innerClass += ` max-w-screen-xl section-x-adjust py-12 mx-auto`
  else rootClass += ` max-w-screen-xl section-x-adjust py-12 mx-auto`

  return (
    <div className={rootClass}>
      <div className={innerClass}>
        <div className="lg:text-center">
          {title && (
            <p className="text-base font-semibold leading-6 tracking-wide uppercase text-primary-600">
              {title}
            </p>
          )}

          {subtitle && (
            <h3 className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              {subtitle}
            </h3>
          )}

          {text && (
            <p className="max-w-2xl mt-4 text-xl leading-7 text-gray-500 lg:mx-auto">
              {text}
            </p>
          )}
        </div>

        {children}
      </div>
    </div>
  )
}

Section.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.any,
  full: PropTypes.bool,
}

Section.defaultProps = {
  children: null,
  background: "bg-white",
  full: false,
}

export default Section
