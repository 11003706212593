import React, { useState } from "react"
import Img from "gatsby-image/withIEPolyfill"
import PropTypes from "prop-types"
import Main from "./main"
import Navigation from "./navigation"
import NavigationMobile from "./navigationMobile"

const NAVIGATION = [
  { label: "Home", to: "/" },
  { label: "Over ons", to: "/about" },
  { label: "Informatie", to: "/info" },
  { label: "Contact", to: "/contact" },
]

const Header = ({ title, hero, isHome }) => {
  const [menu, toggleMenu] = useState(false)
  const imageWrapperClasses = `lg:flex lg:items-center lg:pb-8`
  const imageClasses = `hidden lg:block w-1/2 p-8`
  const rootClass = `relative overflow-hidden ${
    isHome ? "bg-gradient-to-br via-white from-primary-200 to-white" : ""
  }`

  return (
    <header className={rootClass}>
      <div className="section-x-adjust">
        <Navigation
          items={NAVIGATION}
          menu={menu}
          title={title}
          isHome={isHome}
          toggleMenu={toggleMenu}
        />

        <NavigationMobile
          items={NAVIGATION}
          menu={menu}
          title={title}
          isHome={isHome}
          toggleMenu={toggleMenu}
          cta={hero?.cta?.primary}
        />

        {hero && (
          <div className={imageWrapperClasses}>
            <Main hero={hero} />

            {hero?.image && (
              <div className={imageClasses}>
                <Img
                  className="shadow-2xl rounded-xl"
                  durationFadeIn={1000}
                  fluid={hero.image}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  )
}

const HeroShape = {
  text: PropTypes.string,
  headline: PropTypes.string.isRequired,
  headlineAccent: PropTypes.string.isRequired,
  image: PropTypes.shape({}),
  cta: PropTypes.shape({
    primary: PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    }),
    secondary: PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    }),
  }),
}

Header.propTypes = {
  hero: PropTypes.shape(HeroShape),
  isHome: PropTypes.bool,
}

export default Header
