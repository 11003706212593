import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const HeaderMain = ({ hero }) => {
  if (!hero) return null

  return (
    <main className="flex-1 pt-12 pr-12 lg:py-12">
      <div className="sm:text-center lg:text-left">
        {hero?.headline && hero?.headlineAccent && (
          <h2 className="text-4xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
            {hero.headline}
            <br />
            <span className="text-primary-600"> {hero.headlineAccent}</span>
          </h2>
        )}

        {hero?.text && (
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            {hero.text}
          </p>
        )}

        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          {hero?.cta?.primary?.label && (
            <div className="rounded-md shadow">
              <Link
                to={hero.cta.primary.to}
                className="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out border border-transparent rounded-md bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-600 focus:shadow-outline-primary md:py-4 md:text-lg md:px-10"
              >
                {hero.cta.primary.label}
              </Link>
            </div>
          )}

          {hero?.cta?.secondary?.label && (
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <Link
                to={hero.cta.secondary.to}
                className="flex items-center justify-center w-full px-8 py-3 text-base font-medium leading-6 transition duration-150 ease-in-out border border-transparent rounded-md text-primary-600 bg-primary-100 hover:text-primary-500 hover:bg-primary-50 focus:outline-none focus:shadow-outline-primary focus:border-primary-300 md:py-4 md:text-lg md:px-10"
              >
                {hero.cta.secondary.label}
              </Link>
            </div>
          )}
        </div>
      </div>
    </main>
  )
}

const HeroShape = {
  text: PropTypes.string,
  headline: PropTypes.string.isRequired,
  headlineAccent: PropTypes.string.isRequired,
  cta: PropTypes.shape({
    primary: PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    }),
    secondary: PropTypes.shape({
      label: PropTypes.string,
      to: PropTypes.string,
    }),
  }),
}

HeaderMain.propTypes = {
  hero: PropTypes.shape(HeroShape),
}

export default HeaderMain
