import React from "react"
import PropTypes from "prop-types"
import Brand from "./brand"
import { Link } from "gatsby"

const FACEBOOK = (
  <svg
    fill="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    className="w-5 h-5"
    viewBox="0 0 24 24"
  >
    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
  </svg>
)

const LINKEDIN = (
  <svg
    fill="currentColor"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="0"
    className="w-5 h-5"
    viewBox="0 0 24 24"
  >
    <path
      stroke="none"
      d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
    ></path>
    <circle cx="4" cy="4" r="2" stroke="none"></circle>
  </svg>
)

const INSTAGRAM = (
  <svg
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    className="w-5 h-5"
    viewBox="0 0 24 24"
  >
    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
  </svg>
)

// const SOCIAL_MEDIA = [
//   { icon: FACEBOOK, label: "Facebook", to: "/" },
//   { icon: INSTAGRAM, label: "Instagram", to: "/" },
//   { icon: LINKEDIN, label: "LinkedIn", to: "/" },
// ]

const SOCIAL_MEDIA = []

const Footer = ({ title }) => (
  <footer className="text-gray-700 section-x body-font">
    <div className="flex flex-col items-center px-4 py-8 sm:px-6 lg:px-8 sm:flex-row">
      <Brand small title={title} />

      <p className="mt-4 text-sm text-gray-500 sm:pl-4 sm:ml-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0">
        © 2020
      </p>

      {SOCIAL_MEDIA?.length > 0 && (
        <span className="inline-flex justify-center mt-4 sm:ml-auto sm:mt-0 sm:justify-start">
          {SOCIAL_MEDIA.map(({ icon, label, to }) => (
            <Link to={to} key={label} className="ml-3 text-gray-500">
              {icon}
            </Link>
          ))}
        </span>
      )}
    </div>
  </footer>
)

Footer.propTypes = {
  title: PropTypes.string,
}

export default Footer
